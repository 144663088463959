import {flare} from '@flareapp/flare-client';

flare.beforeSubmit = (report) => {

    if ([
        'Request failed with status code 401',
        'Network Error',
        'canceled',
        'Importing a module script failed.',
        'is not a valid JavaScript MIME type',
        'Cannot create proxy with a non-object as target or handler',
        'Unable to preload CSS',
        'timeout exceeded',
    ].some(v => report.message.includes(v))) {
        return false;
    }
    
    // Don't send reports from bots
    if (report.context.request.useragent.toLocaleLowerCase().includes('adsbot')) {
        return false;
    }

    return report;
};

export {flare};

